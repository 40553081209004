import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';
import {Assignment} from '@material-ui/icons';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import placeholderButton from 'components/Editor/placeholderButton';
import {graphQLApi, graphQLReduceFields} from "../../../services/GraphQLApi";
import EditForm from "../../../components/Form/EditForm";
import {config} from "../../../config";

export default function AssignmentEdit(props) {
    const intl = useIntl();
    let id = Number(props.match.params.id);
    let assignmentId = props.match.params.assignmentID;
    if (!id && assignmentId) {
        id = assignmentId;
    }

    let fields = [
        {
            column: 1,
            field: "name",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.name", defaultMessage: "Name"}),
            input: "text"
        },
        {
            column: 1,
            field: "customer_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "assignments.edit.label.customer_id", defaultMessage: "Customer"}),
            query: "customers",
            filter: "",
            titleField: "name",
            allowAdd: true,
            onChange: (value, data, setData) => {
                setData({...data, consultants:[], contact_id:null})
            }
        },
        {
            column: 1,
            field: "contact_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "assignments.edit.label.contact_id", defaultMessage: "Contact"}),
            query: "customerContacts",
            titleField: "name",
            allowAdd: true,
            filterBy: "customer_id"
        },
        {
            column: 1,
            field: "consultants",
            initial: [],
            type: "[ID]",
            label: intl.formatMessage({id: "assignments.edit.label.consultants", defaultMessage: "Consultants"}),
            query: "customerContacts",
            titleField: "name",
            allowAdd: true,
            filterBy: "customer_id"
        },
        {
            column: 1,
            field: "manager_id",
            initial: {id: authUser().id, name: authUser().name},
            type: "ID",
            label: intl.formatMessage({id: "assignments.edit.label.manager_id", defaultMessage: "Manager"}),
            query: "users",
            filter: 'role:"admin,manager"',
            titleField: "name"
        },
        {
            column: 1,
            field: "provider_customer_id",
            initial: null,
            type: "ID",
            label: intl.formatMessage({id: "assignments.edit.label.provider_customer_id", defaultMessage: "Provider Customer"}),
            query: "customers",
            filter: "",
            titleField: "name"
        },
        {
            column: 1,
            field: "contract",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.contract", defaultMessage: "Contract"}),
            input: "file",
            accepts: "*",
        },
        {
            column: 1,
            field: "last_report_at",
            initial: null,
            disabled: () => true,
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.last_report_at", defaultMessage: "Last Report At"}),
            input: "datetime"
        },
        {
            column: 1,
            field: "last_billed_at",
            initial: null,
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.last_billed_at", defaultMessage: "Last Billed At"}),
            input: "datetime"
        },
        {
            column: 2,
            field: "allow_sending_meeting_invites",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_meeting_invites", defaultMessage: "Allow phoner to send meeting invites to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_direct_mail",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_direct_mail", defaultMessage: "Allow phoner to send direct mails to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_alt_mail_1",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_alt_mail_1", defaultMessage: "Allow phoner to send alternativ 1 mails to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_alt_mail_2",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_alt_mail_2", defaultMessage: "Allow phoner to send alternativ 2 mails to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "allow_sending_sms",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.allow_sending_sms", defaultMessage: "Allow phoner to send SMS messages to subjects"}),
            input: "switch"
        },
        {
            column: 2,
            field: "add_phoner_comment_details",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.add_phoner_comment_details", defaultMessage: "Add phoner name and date on comments"}),
            input: "switch"
        },
        {
            column: 2,
            field: "is_manager_bcc",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.is_manager_bcc", defaultMessage: "BCC manager on all mails"}),
            input: "switch"
        },
        {
            column: 2,
            field: "reset_closed_subjects_daily",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.reset_closed_subjects_daily", defaultMessage: "Reset closed subjects daily"}),
            input: "switch"
        },
        {
            column: 2,
            field: "max_closed_subjects",
            initial: '',
            type: "Int",
            label: intl.formatMessage({id: "assignments.edit.label.max_closed_subjects", defaultMessage: "Maximum number of closed subjects"}),
            input: "number"
        },
        {
            column: 2,
            field: "auto_assign_subjects",
            initial: '',
            type: "Int",
            label: intl.formatMessage({id: "assignments.edit.label.auto_assign_subjects", defaultMessage: "Auto assing subjects when phoner has no more ready subjects"}),
            input: "number"
        },
        {
            column: 2,
            field: "low_subjects_alert_level",
            initial: '',
            type: "Int",
            label: intl.formatMessage({id: "assignments.edit.label.low_subjects_alert_level", defaultMessage: "Below this amount of ready subjects sends alert email"}),
            input: "number"
        },
        {
            column: 2,
            field: "low_subjects_alert_recipients",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.low_subjects_alert_recipients", defaultMessage: "Alert email recipients"}),
            help: intl.formatMessage({id: "assignments.edit.help.low_subjects_alert_recipients", defaultMessage: "Leave empty to send to the manager."}),
            input: "text"
        },
        {
            column: 3,
            field: "sms_template",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.sms_template", defaultMessage: "SMS Template"}),
            input: "html",
            config: {
                minHeight: 475,
                toolbarAdaptive: false,
                buttons: [placeholderButton(intl, [
                    {
                        id: 'name',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.name',
                            defaultMessage: 'Name',
                        }),
                    },
                    {
                        id: 'addressLine1',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.addressLine1',
                            defaultMessage: 'Address Line 1',
                        }),
                    },
                    {
                        id: 'addressLine2',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.addressLine2',
                            defaultMessage: 'Address Line 2',
                        }),
                    },
                    {
                        id: 'postcode',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.postcode',
                            defaultMessage: 'Postal Code',
                        }),
                    },
                    {
                        id: 'city',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.city',
                            defaultMessage: 'City',
                        }),
                    },
                    {
                        id: 'state',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.state',
                            defaultMessage: 'State',
                        }),
                    },
                    {
                        id: 'countryCode',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.country_code',
                            defaultMessage: 'Country code',
                        }),
                    },
                    {
                        id: 'meetingAddressLine1',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.meetingAddressLine1',
                            defaultMessage: 'Meeting Address Line 1',
                        }),
                    },
                    {
                        id: 'meetingAddressLine2',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.meetingAddressLine2',
                            defaultMessage: 'Meeting Address Line 2',
                        }),
                    },
                    {
                        id: 'meetingPostcode',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.meetingPostcode',
                            defaultMessage: 'Meeting Postal Code',
                        }),
                    },
                    {
                        id: 'meetingCity',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.meetingCity',
                            defaultMessage: 'Meeting City',
                        }),
                    },
                    {
                        id: 'email',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.email',
                            defaultMessage: 'Email',
                        }),
                    },
                    {
                        id: 'landlineNo',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.landlineNo',
                            defaultMessage: 'Landline Number',
                        }),
                    },
                    {
                        id: 'directNo',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.directNo',
                            defaultMessage: 'Direct Number',
                        }),
                    },
                    {
                        id: 'cellNo',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.cellNo',
                            defaultMessage: 'Cell Number',
                        }),
                    },
                    {
                        id: 'businessType',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.businessType',
                            defaultMessage: 'Business Type',
                        }),
                    },
                    {
                        id: 'employees',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.employees',
                            defaultMessage: 'Employees',
                        }),
                    },
                    {
                        id: 'www',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.www',
                            defaultMessage: 'Website',
                        }),
                    },
                    {
                        id: 'nameContact',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.nameContact',
                            defaultMessage: 'Name Contact',
                        }),
                    },
                    {
                        id: 'titleContact',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.titleContact',
                            defaultMessage: 'Title Contact',
                        }),
                    },
                    {
                        id: 'emailContact',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.emailContact',
                            defaultMessage: 'Email Contact',
                        }),
                    },
                    {
                        id: 'departmentIDContact',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.departmentIDContact',
                            defaultMessage: 'Department ID Contact',
                        }),
                    },
                    {
                        id: 'handlingComment',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.handlingComment',
                            defaultMessage: 'Handling Comment',
                        }),
                    },
                    {
                        id: 'externalComment',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.externalComment',
                            defaultMessage: 'External Comment',
                        }),
                    },
                    {
                        id: 'lastEditedByManager',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.lastEditedByManager',
                            defaultMessage: 'Last Edited By Manager',
                        }),
                    },
                    {
                        id: 'lastEditedByPhoner',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.lastEditedByPhoner',
                            defaultMessage: 'Last Edited By Phoner',
                        }),
                    },
                    {
                        id: 'meetingEstablished',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.meetingEstablished',
                            defaultMessage: 'Meeting Established',
                        }),
                    },
                    {
                        id: 'nextContact',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.nextContact',
                            defaultMessage: 'Next Contact',
                        }),
                    },
                    {
                        id: 'outcomeID',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.outcomeID',
                            defaultMessage: 'Outcome',
                        }),
                    },
                    {
                        id: 'phonerID',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.phonerID',
                            defaultMessage: 'Phoner',
                        }),
                    },
                    {
                        id: 'todaysDate',
                        type: 'static',
                        name: intl.formatMessage({
                            id: 'templates.editor.placeholder.todaysDate',
                            defaultMessage: 'Todays Date',
                        }),
                    },
                ])]
            },
        },
        {
            column: 3,
            field: "is_screening",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.is_screening", defaultMessage: "Is Screening"}),
            input: "switch"
        },
        {
            column: 3,
            field: "is_template",
            initial: false,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.is_template", defaultMessage: "Is Template"}),
            input: "switch"
        },
        {
            column: 3,
            field: "is_active",
            initial: true,
            type: "Boolean",
            label: intl.formatMessage({id: "assignments.edit.label.is_active", defaultMessage: "Is Active"}),
            input: "switch"
        },
        {
            fullWidth: true,
            field: "phoners",
            initial: [],
            multiple: true,
            type: "[ID]",
            label: intl.formatMessage({id: "assignments.edit.label.phoners", defaultMessage: "Phoners"}),
            query: "users",
            filter: 'role:"employee"',
            titleField: "name"
        },
    ];
    if (!assignmentId) {
        fields.push({
            fullWidth: true,
            field: "notes",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assignments.edit.label.notes", defaultMessage: "Notes"}),
            input: "html",
        });
    }
    const [isLoading, setIsLoading] = useState(false);
    const initialValidation = graphQLReduceFields(fields, 'validation');
    const [data, setData] = useState(graphQLReduceFields(fields, 'initial'))
    const [validation, setValidation] = useState(graphQLReduceFields(fields, 'validation'));
    const setValidationFromErrors = (errors) => {
        if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
            setValidation({...initialValidation, ...errors[0].extensions.validation});
        }
    };

    const client = new graphQLApi(useAuthDispatch(), props.history, null, {handleErrors: setValidationFromErrors});

    useEffect(() => {
        if (id) {
            setIsLoading(true)
            client.query('{assignments(filter:{id:'+id+'}) {data{id '+graphQLReduceFields(fields, 'fields')+'}}}').then(response => {
                if (response) {
                    const d = response.assignments.data[0]
                    setData({...d,
                        customer_id: d.customer,
                        contact_id: d.contact,
                        manager_id: d.manager,
                        provider_customer_id:d.provider_customer,
                    })
                }
            })
            setIsLoading(false)
        }
    }, [])

    const onSave = async (d) => {
        const cus = authUser().customer;
        const country = cus ? cus.country_code : config.countryCode
        const language = cus ? cus.language_code : config.languageCode

        if (d.customer_id && d.customer_id.id === 'new') {
            d.customer_id = await client.mutate('($name:String!, $countryCode:String!, $languageCode:String!) {customerCreate(name:$name, country_code:$countryCode, language_code:$languageCode) {id name}}',
                {name: d.customer_id.name, countryCode:country, languageCode:language}).then(res => {
                if (res?.hasOwnProperty('customerCreate')) return {id: res.customerCreate.id, name: res.customerCreate.name}
            })
        }
        if (d.contact_id && d.contact_id.id === 'new') {
            d.contact_id = await client.mutate('($name:String! $customerId:ID) {customerContactCreate(name:$name customer_id:$customerId) {id name}}',
                {name: d.contact_id.name, customerId: d.customer_id.id}).then(res => {
                if (res?.hasOwnProperty('customerContactCreate')) return {id: res.customerContactCreate.id, name: res.customerContactCreate.name}

            })
        }

        for (let i in d.consultants) {
            if (d.consultants[i].id === 'new') {
                d.consultants[i] = await client.mutate('($name:String! $customerId:ID) {customerContactCreate(name:$name customer_id:$customerId) {id name}}',
                    {name: d.consultants[i].inputValue, customerId: d.customer_id.id}).then(res => {
                    if (res?.hasOwnProperty('customerContactCreate')) return {id: res.customerContactCreate.id, name: res.customerContactCreate.name};
                })
            }
        }
        const vars = graphQLReduceFields(fields, 'variables');
        if (id) {
            vars.id = 'ID!';
            d.id = id;
        }
        client
            .mutation('assignment', vars, d, 'id ' + graphQLReduceFields(fields, 'fields'))
            .then(r => {
                if (r && r.response) {
                    if (typeof props.onSave === "function")
                        props.onSave();
                    else
                        props.history.goBack();
                }
            });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Assignment color="primary"/>}
                        title={id
                          ? intl.formatMessage({id: "assignments.edit.heading", defaultMessage:"Assignments edit"})
                          : intl.formatMessage({id: "assignments.create.heading", defaultMessage:"Assignments create"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <EditForm
                            fields={fields}
                            data={data}
                            setData={setData}
                            isLoading={isLoading}
                            save={onSave}
                            validation={validation}
                            cols={3}
                            />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
